.product_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .product_detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 10px;
    padding: 10px;

    .product_image {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      padding-bottom: 30%;
    }
  }
}

@primary-color: #fbcb4c;@secondary-color: #ffb900;@primary-background: #eff7f6;@dark-pink: #f2b5d4;@light-pink: #f7d6e0;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;