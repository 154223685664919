#frontpage_hero_section {
  background: linear-gradient(to right, @primary-color, @secondary-color);
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }

  h1 {
    font-size: 3em;
  }

  #bottom_divider {
    position: absolute;
    width: 100%;
    padding-bottom: 25%;
    bottom: 0;
    overflow: hidden;

    #bottom_divider_inner {
      background: white;
      transform: rotate(-5deg);
      height: 100%;
      position: absolute;
      width: 120%;
      bottom: -80%;
      left: -10%;
    }
  }

  .logo_container {
    background: white;
    display: inline-block;
    height: 240px;
    width: 240px;
    border-radius: 100%;
    padding: 25px;
  }
}

@primary-color: #fbcb4c;@secondary-color: #ffb900;@primary-background: #eff7f6;@dark-pink: #f2b5d4;@light-pink: #f7d6e0;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;