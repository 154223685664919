.header {
    z-index: 1;
    background: linear-gradient(to right, @primary-color, @secondary-color);
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);

    @media (max-width: 500px) {
        padding: 0 10px;
    }

    .main_menu {
        line-height: 62px !important;
        display: flex;
        background: none;
    }

    .ant-menu-submenu {
        padding: 0 !important;
    }

    .ant-menu-overflowed-submenu {
        margin-left: auto;
    }

    .hide-group-title {
        .ant-menu-item-group-title {
            display: none;
        }
    }
}

#main_header_row {
    flex-wrap: wrap;
    justify-content: space-between;

    #main_navigation {
        background: transparent;
        border: none;
    }

    #logo_col {
        order: 0;
        min-width: 120px;
        max-width: 120px;
    }

    #menu_col {
        order: 1;
        flex-grow: 1;
    }

    #user_col {
        order: 2;
    }

    @media (max-width: 800px) {
        #menu_col {
            order: 2;
            min-width: 100%;
        }

        #user_col {
            order: 1;
        }
    }
}

@primary-color: #fbcb4c;@secondary-color: #ffb900;@primary-background: #eff7f6;@dark-pink: #f2b5d4;@light-pink: #f7d6e0;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;