@import '~antd/dist/antd.less';

.ant-picker-calendar-header {
  padding: 10px;

  .ant-picker-calendar-mode-switch {
    display: none;
  }
}

h1 {
  font-size: 2em;
}

img {
  max-width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.btn-success {
  background: @success-color;
  border: @success-color;
  color: #fff;

  &:hover {
    background: lighten(@success-color, 10%);
    color: #fff;
  }

  &:visited,
  &:active,
  &:focus {
    background: @success-color;
  }
}

.bold {
  font-weight: bold;
}

.successColor {
  color: @success-color;
}

.warningColor {
  color: @warning-color;
}

.errorColor {
  color: @error-color;
}

.ant-layout {
  background: transparent;
}

.ant-card {
  background: rgba(255, 255, 255, 0.9);
}

#mainContainer {
  min-height: ~'calc(100vh - 134px)';
}

.success_color {
  color: #52c41a;
}

.warning_color {
  color: #dccc00;
}

.capitalize_first_letter {
  display: block;

  &::first-letter {
    text-transform: capitalize;
  }
}

.next_event_time_until {
  background: #ffffff;
  color: #2f90ff;
  padding: 5px;
}

.site-layout-background {
  background: #fff;
  padding: 24;
  margin: 0;
  min-height: 100vh !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.mb0 {
  margin-bottom: 0;
}

.mt0 {
  margin-top: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.ant-card {
  &.info {
    .ant-card-head {
      background: #2f90ff;
      color: #fff;
    }

    .ant-card-body {
      background: rgba(47, 144, 255, 0.09);
    }
  }
}

.ant-layout-sider-trigger {
  position: absolute !important;
}

@primary-color: #fbcb4c;@secondary-color: #ffb900;@primary-background: #eff7f6;@dark-pink: #f2b5d4;@light-pink: #f7d6e0;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;